import { useSnackQuery, useSnackMutation } from 'Iaso/libs/apiHooks';
import { getRequest, deleteRequest, putRequest } from 'Iaso/libs/Api';
import { makeUrlWithParams } from 'Iaso/libs/utils';

import { Coordination, CoordinationParams } from '../types';

import MESSAGES from '../messages';

export const useGetCoordinations = (params: CoordinationParams) => {
    const newParams: CoordinationParams = {
        order: params.order || 'name',
    };
    const url = makeUrlWithParams('/api/trypelim/coordinations/', newParams);

    return useSnackQuery({
        queryKey: ['coordinations', newParams],
        queryFn: () => getRequest(url),
        options: {
            keepPreviousData: true,
        },
    });
};

export const useSaveCoordination = () =>
    useSnackMutation({
        mutationFn: (body: Coordination) =>
            putRequest(`/api/trypelim/coordinations/${body.id}/`, body),
        invalidateQueryKey: ['coordinations'],
    });

export const useDeleteCoordination = () =>
    useSnackMutation({
        mutationFn: (id: number) =>
            deleteRequest(`/api/trypelim/coordinations/${id}/`),
        snackSuccessMessage: MESSAGES.deleteSuccess,
        snackErrorMsg: MESSAGES.deleteError,
        invalidateQueryKey: ['coordinations'],
    });

export const useGetOrgUnitTypes = () =>
    useSnackQuery({
        queryKey: ['org_unit_types'],
        queryFn: () => getRequest('/api/v2/orgunittypes/'),
        options: {
            keepPreviousData: true,
        },
    });
