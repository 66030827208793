import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
    coordinations: {
        defaultMessage: 'Coordinations',
        id: 'trypelim.navigation.management.coordinations',
    },
    actions: {
        defaultMessage: 'Actions',
        id: 'main.label.actions',
    },
    edit: {
        defaultMessage: 'Edit',
        id: 'main.label.edit',
    },
    cancel: {
        defaultMessage: 'Cancel',
        id: 'main.label.cancel',
    },
    save: {
        defaultMessage: 'Save',
        id: 'main.label.save',
    },
    requiredField: {
        id: 'iaso.forms.error.fieldRequired',
        defaultMessage: 'This field is required',
    },
    name: {
        defaultMessage: 'Name',
        id: 'main.label.name',
    },
    contactEmails: {
        defaultMessage: 'Contact emails',
        id: 'main.label.contact_emails',
    },
    contactPhones: {
        defaultMessage: 'Contact phones',
        id: 'main.label.contact_phones',
    },
    zones: {
        defaultMessage: 'Zones',
        id: 'main.label.zone',
    },
    delete: {
        defaultMessage: 'Delete',
        id: 'main.label.delete',
    },
    deleteError: {
        id: 'iaso.snackBar.delete_error',
        defaultMessage: 'An error occurred while deleting this resource',
    },
    deleteSuccess: {
        id: 'iaso.snackBar.delete_successful',
        defaultMessage: 'Deleted successfully',
    },
    deleteWarning: {
        id: 'iaso.label.deleteWarning',
        defaultMessage: 'Are you sure you want to delete {name}?',
    },
});

export default MESSAGES;
