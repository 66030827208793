import { useSnackQuery, useSnackMutation } from 'Iaso/libs/apiHooks';
import { getRequest, patchRequest } from 'Iaso/libs/Api';
import { makeUrlWithParams } from 'Iaso/libs/utils';

import { HealthStructure, HealthStructureParams } from '../types';

export const useGetHealthStructures = (params: HealthStructureParams) => {
    const newParams: HealthStructureParams = {
        limit: params.pageSize || 20,
        page: params.page || 1,
        order: params.order || 'name',
        as_list: true,
    };
    if (params.search) {
        newParams.search = params.search;
    }

    const url = makeUrlWithParams('/api/trypelim/healthstructures/', newParams);

    return useSnackQuery({
        queryKey: ['healthStructures', newParams],
        queryFn: () => getRequest(url),
        options: {
            keepPreviousData: true,
        },
    });
};

export const useSaveHealthStructure = () =>
    useSnackMutation({
        mutationFn: (body: HealthStructure) =>
            patchRequest(`/api/trypelim/healthstructures/${body.id}/`, body),
        invalidateQueryKey: ['healthStructures'],
    });
