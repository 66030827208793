import React, { FunctionComponent } from 'react';
import { Box, Divider } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import { isEqual } from 'lodash';
import {
    AddButton,
    ArrayFieldInput,
    ConfirmCancelModal,
    IntlMessage,
    makeFullModal,
    useSafeIntl,
} from 'bluesquare-components';
import MESSAGES from '../messages';
import { MutateFunction } from 'react-query';
import { HealthStructure } from '../types';
import { useHealthStructureSchema } from '../hooks/validation';

import TextInput from 'Iaso/domains/pages/components/TextInput';
import { EditIconButton } from 'Iaso/components/Buttons/EditIconButton';

type Props = {
    titleMessage: IntlMessage;
    isOpen: boolean;
    closeDialog: () => void;
    healthStructure?: HealthStructure;
    saveHealthStructure: MutateFunction<HealthStructure, any>;
};

const CreateEditHealthStructureDialog: FunctionComponent<Props> = ({
    titleMessage,
    isOpen,
    closeDialog,
    healthStructure,
    saveHealthStructure,
}) => {
    const { formatMessage } = useSafeIntl();
    const schema = useHealthStructureSchema();
    const formik = useFormik({
        initialValues: {
            id: healthStructure?.id,
            name: healthStructure?.name,
            contact_emails: healthStructure?.contact_emails,
            contact_phones: healthStructure?.contact_phones,
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: schema,
        onSubmit: async values => {
            saveHealthStructure(values);
        },
    });
    const isFormChanged = !isEqual(formik.values, formik.initialValues);
    const allowConfirm =
        !formik.isSubmitting && formik.isValid && isFormChanged;

    return (
        <FormikProvider value={formik}>
            <ConfirmCancelModal
                titleMessage={titleMessage}
                onConfirm={() => formik.handleSubmit()}
                cancelMessage={MESSAGES.cancel}
                confirmMessage={MESSAGES.save}
                maxWidth="md"
                open={isOpen}
                closeDialog={closeDialog}
                onClose={() => null}
                allowConfirm={allowConfirm}
                onCancel={() => null}
                id="health-structure-dialog"
                dataTestId="health-structure-dialog"
            >
                <Box mb={2}>
                    <Divider />
                </Box>
                <Box mb={2}>
                    <Field
                        label={formatMessage(MESSAGES.name)}
                        name="name"
                        component={TextInput}
                        required
                    />
                </Box>
                <Box mb={2}>
                    <Field
                        label={formatMessage(MESSAGES.contactEmails)}
                        name="contact_emails"
                        component={ArrayFieldInput}
                        fieldList={formik.values.contact_emails}
                        baseId="contact_emails"
                        updateList={list => {
                            formik.setFieldValue('contact_emails', list);
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <Field
                        label={formatMessage(MESSAGES.contactPhones)}
                        name="contact_phones"
                        component={ArrayFieldInput}
                        fieldList={formik.values.contact_phones}
                        baseId="contact_phones"
                        updateList={list => {
                            formik.setFieldValue('contact_phones', list);
                        }}
                    />
                </Box>
            </ConfirmCancelModal>
        </FormikProvider>
    );
};

const modalWithIcon = makeFullModal(
    CreateEditHealthStructureDialog,
    EditIconButton,
);
const modalWithButton = makeFullModal(
    CreateEditHealthStructureDialog,
    AddButton,
);

export { modalWithIcon as EditHealthStructureDialog };
export { modalWithButton as CreateHealthStructureDialog };
