import moment from 'moment';

import { useSnackQuery } from 'Iaso/libs/apiHooks';
import { getRequest } from 'Iaso/libs/Api';

export const useGetHomeDatas = () => {
    const currentYear = new Date().getFullYear() + 1;
    const homeYears = [1, 2, 3, 4].map(i => currentYear - i);
    const dateFrom = moment()
        .startOf('year')
        .subtract(5, 'year')
        .format('YYYY-MM-DD');
    const dateTo = moment()
        .endOf('year')
        .subtract(1, 'year')
        .format('YYYY-MM-DD');
    const url = `/api/trypelim/home/?from=${dateFrom}&to=${dateTo}&years=${homeYears}`;

    return useSnackQuery({
        queryKey: ['homeDatas'],
        queryFn: () => getRequest(url),
        options: {
            keepPreviousData: true,
        },
    });
};
