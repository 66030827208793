import React from 'react';
import { menu } from './configMenu';
import en from './translations/en.json';
import fr from './translations/fr.json';
import { paginationPathParams } from '../../../../hat/assets/js/apps/Iaso/routing/common.ts';

import { RedirectToOldDashboard } from './RedirectToOldDashboard';

import { routes } from './src/constants/routes';
import { baseUrls, paramsConfig } from './src/constants/urls';

// Note: these routes are defined here:
// - plugins/trypelim/dashboard/urls.py
// - plugins/trypelim/dashboard/views.py
const redirections = [
    {
        path: '/old-trypelim/*',
        element: <RedirectToOldDashboard />,
    },
];

const translations = {
    fr,
    en,
};

export default {
    baseUrls,
    paramsConfig,
    routes,
    menu,
    translations,
    redirections,
    homeUrl: '/trypelim/home',
};
