import React, { FunctionComponent } from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
    commonStyles,
    Table,
    useRedirectTo,
    useSafeIntl,
} from 'bluesquare-components';

import TopBar from 'Iaso/components/nav/TopBarComponent';
import { baseUrls } from '../../../constants/urls';

import { useGetColumns } from './tableColumns';
import MESSAGES from './messages';
import { CoordinationParams } from './types';
import {
    useGetCoordinations,
    useSaveCoordination,
    useDeleteCoordination,
    useGetOrgUnitTypes,
} from './hooks/requests';
import { useParamsObject } from '../../../../../../../../hat/assets/js/apps/Iaso/routing/hooks/useParamsObject';

const useStyles = makeStyles(theme => ({
    ...commonStyles(theme),
}));

type Props = {
    params: CoordinationParams;
};

const baseUrl = baseUrls.coordinations;
export const ManagementCoordinations: FunctionComponent = () => {
    const params = useParamsObject(baseUrl);
    const redirectTo = useRedirectTo();
    const { formatMessage } = useSafeIntl();
    const classes = useStyles();

    const { data, isFetching } = useGetCoordinations(params);
    const { data: orgUnitTypes, isFetching: isFetchingOrgUnitTypes } =
        useGetOrgUnitTypes();
    const { mutateAsync: saveCoordination, isLoading: saving } =
        useSaveCoordination();
    const { mutate: deleteCoordination, isLoading: deleting } =
        useDeleteCoordination();

    let orgUnitTypeZoneId = 0;

    if (!isFetchingOrgUnitTypes) {
        orgUnitTypeZoneId = orgUnitTypes.orgUnitTypes.find(
            out => out.short_name === 'zs',
        ).id;
    }

    const columns = useGetColumns(
        saveCoordination,
        deleteCoordination,
        orgUnitTypeZoneId,
    );

    const isLoading =
        isFetching || isFetchingOrgUnitTypes || deleting || saving;

    return (
        <>
            <TopBar
                title={formatMessage(MESSAGES.coordinations)}
                displayBackButton={false}
            />
            <Box className={classes.containerFullHeightNoTabPadded}>
                <Table
                    data={data ?? []}
                    defaultSorted={[{ id: 'name', desc: false }]}
                    columns={columns}
                    baseUrl={baseUrl}
                    params={params}
                    extraProps={{
                        loading: isLoading,
                    }}
                    showPagination={false}
                    onTableParamsChange={p => redirectTo(baseUrl, p)}
                />
            </Box>
        </>
    );
};
