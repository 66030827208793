import React from 'react';
import { MutateFunction } from 'react-query';

import {
    // @ts-ignore
    IconButton as IconButtonComponent,
    Column,
    useSafeIntl,
} from 'bluesquare-components';

import { baseUrls } from 'Iaso/constants/urls';
import MESSAGES from './messages';
import { EditHealthStructureDialog } from './components/HealthStructureDialog';
import { HealthStructure } from './types';

export const useGetColumns = (
    saveHealthStructure: MutateFunction<HealthStructure, any>,
): Column[] => {
    const { formatMessage } = useSafeIntl();

    return [
        {
            Header: formatMessage({
                defaultMessage: 'Name',
                id: 'main.label.name',
            }),
            width: 350,
            accessor: 'name',
        },
        {
            Header: formatMessage({
                defaultMessage: 'Province',
                id: 'main.label.province',
            }),
            className: 'small',
            sortable: false,
            accessor: 'AS.province_name',
        },
        {
            Header: formatMessage({
                defaultMessage: 'Zone',
                id: 'main.label.zone_short',
            }),
            className: 'small',
            sortable: false,
            accessor: 'AS.zs_name',
        },
        {
            Header: formatMessage({
                defaultMessage: 'Area',
                id: 'main.label.area_short',
            }),
            className: 'small',
            sortable: false,
            accessor: 'AS.name',
        },
        {
            Header: formatMessage({
                defaultMessage: 'Contact details',
                id: 'main.label.contactDetails',
            }),
            sortable: false,
            Cell: settings => {
                const { contact_emails, contact_phones } =
                    settings.row.original;

                return (
                    <section>
                        {contact_emails && (
                            <div>{contact_emails.join(', ')}</div>
                        )}
                        {contact_phones && (
                            <div>{contact_phones.join(', ')}</div>
                        )}
                    </section>
                );
            },
        },
        {
            Header: formatMessage(MESSAGES.actions),
            sortable: false,
            resizable: false,
            width: 300,
            Cell: settings => (
                <section>
                    <IconButtonComponent
                        id={`org-unit-link-${settings.row.original.id}`}
                        url={`/${baseUrls.orgUnitDetails}/orgUnitId/${settings.row.original.id}`}
                        icon="remove-red-eye"
                        tooltipMessage={MESSAGES.view}
                    />
                    <EditHealthStructureDialog
                        healthStructure={settings.row.original}
                        titleMessage={MESSAGES.edit}
                        saveHealthStructure={saveHealthStructure}
                    />
                </section>
            ),
        },
    ];
};
