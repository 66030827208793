import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

const HomeBarChart = ({ datas, specs }) => {
    const container = useRef(null);
    let height, x, y, g;

    useEffect(() => {
        drawBaseChart();
        addDataToChart();
    }, []);

    const drawBaseChart = () => {
        if (container.current.hasChildNodes()) {
            container.current.removeChild(container.current.childNodes[0]);
        }

        const width = specs.width - specs.margin.left - specs.margin.right;
        height = specs.height - specs.margin.top - specs.margin.bottom;
        x = d3.scaleBand().rangeRound([0, width]).padding(0.2);
        y = d3.scaleLinear().rangeRound([height, 0]);

        const svg = d3
            .select(container.current)
            .append('svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', `0 0 ${specs.width} ${specs.height}`)
            .attr('preserveAspectRatio', 'xMinYMin');

        g = svg
            .append('g')
            .attr(
                'transform',
                `translate(${specs.margin.left},${specs.margin.top})`,
            );

        x.domain(datas.map(d => d.date));
        y.domain([0, d3.max(datas, d => d.value)]);
        g.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x));

        g.append('g')
            .attr('class', 'axis axis--y')
            .call(d3.axisLeft(y).ticks(5));
    };

    const addDataToChart = () => {
        g.selectAll('.bar')
            .data(datas)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('fill', '#122272')
            .attr('y', height)
            .attr('x', d => x(d.date))
            .attr('width', x.bandwidth())
            .transition()
            .duration(750)
            .delay((d, i) => i * 20)
            .attr('y', d => y(d.value))
            .attr('height', d => height - y(d.value));
    };

    return (
        <section>
            <div className="bar-chart-container" id="home-bar-chart">
                <div ref={container} className="bar-chart full" />
            </div>
        </section>
    );
};

HomeBarChart.defaultProps = {
    specs: {
        width: 1200,
        height: 450,
        margin: {
            top: 20,
            right: 20,
            bottom: 30,
            left: 40,
        },
    },
};

HomeBarChart.propTypes = {
    datas: PropTypes.array.isRequired,
    specs: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
        margin: PropTypes.object,
    }),
};

export default HomeBarChart;
