import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Divider, FormHelperText } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import { isEqual } from 'lodash';
import {
    AddButton,
    ArrayFieldInput,
    ConfirmCancelModal,
    IntlMessage,
    makeFullModal,
    useSafeIntl,
} from 'bluesquare-components';
import MESSAGES from '../messages';
import { MutateFunction } from 'react-query';
import { Coordination } from '../types';
import { useCoordinationSchema } from '../hooks/validation';

import { EditIconButton } from 'Iaso/components/Buttons/EditIconButton';
import { OrgUnitTreeviewModal } from 'Iaso/domains/orgUnits/components/TreeView/OrgUnitTreeviewModal';
import TextInput from 'Iaso/domains/pages/components/TextInput';

type Props = {
    titleMessage: IntlMessage;
    isOpen: boolean;
    closeDialog: () => void;
    coordination?: Coordination;
    saveCoordination: MutateFunction<Coordination>;
    orgUnitTypeZoneId: number;
};

const CreateEditCoordinationDialog: FunctionComponent<Props> = ({
    titleMessage,
    isOpen,
    closeDialog,
    coordination,
    saveCoordination,
    orgUnitTypeZoneId,
}) => {
    const { formatMessage } = useSafeIntl();
    const schema = useCoordinationSchema();
    const formik = useFormik({
        initialValues: {
            id: coordination?.id,
            name: coordination?.name,
            contact_emails: coordination?.contact_emails,
            contact_phones: coordination?.contact_phones,
            org_units: coordination?.org_units,
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: schema,
        onSubmit: async (values: Coordination) => {
            saveCoordination(values);
        },
    });
    const isFormChanged = !isEqual(formik.values, formik.initialValues);
    const allowConfirm =
        !formik.isSubmitting && formik.isValid && isFormChanged;

    return (
        <FormikProvider value={formik}>
            <ConfirmCancelModal
                titleMessage={titleMessage}
                onConfirm={() => formik.handleSubmit()}
                cancelMessage={MESSAGES.cancel}
                confirmMessage={MESSAGES.save}
                maxWidth="md"
                open={isOpen}
                closeDialog={closeDialog}
                onClose={() => null}
                allowConfirm={allowConfirm}
                onCancel={() => null}
                id="coordination-dialog"
                dataTestId="coordination-dialog"
            >
                <Box mb={2}>
                    <Divider />
                </Box>
                <Box mb={2}>
                    <Field
                        label={formatMessage(MESSAGES.name)}
                        name="name"
                        component={TextInput}
                        required
                    />
                </Box>
                <Box mb={2}>
                    <Field
                        label={formatMessage(MESSAGES.contactEmails)}
                        name="contact_emails"
                        component={ArrayFieldInput}
                        fieldList={formik.values.contact_emails}
                        baseId="contact_emails"
                        updateList={(list: string[]) => {
                            formik.setFieldValue('contact_emails', list);
                        }}
                    />
                </Box>
                <Box mb={2}>
                    <Field
                        label={formatMessage(MESSAGES.contactPhones)}
                        name="contact_phones"
                        component={ArrayFieldInput}
                        fieldList={formik.values.contact_phones}
                        baseId="contact_phones"
                        updateList={(list: string[]) => {
                            formik.setFieldValue('contact_phones', list);
                        }}
                    />
                    <FormHelperText>
                        <FormattedMessage
                            id="main.label.contact_phones_info"
                            defaultMessage="Note: Please enter phone numbers with country code but without + or 00 prefix. E.g. 2431111111"
                        />
                    </FormHelperText>
                </Box>
                <OrgUnitTreeviewModal
                    toggleOnLabelClick={false}
                    titleMessage={MESSAGES.zones}
                    onConfirm={(orgUnitList: []) => {
                        formik.setFieldValue('org_units', orgUnitList);
                    }}
                    multiselect
                    initialSelection={coordination?.org_units}
                    allowedTypes={[orgUnitTypeZoneId]}
                />
            </ConfirmCancelModal>
        </FormikProvider>
    );
};

const modalWithIcon = makeFullModal(
    CreateEditCoordinationDialog,
    EditIconButton,
);
const modalWithButton = makeFullModal(CreateEditCoordinationDialog, AddButton);

export { modalWithIcon as EditCoordinationDialog };
export { modalWithButton as CreateCoordinationDialog };
