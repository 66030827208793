/* eslint-disable camelcase */
import * as yup from 'yup';

import { useSafeIntl } from 'bluesquare-components';

import MESSAGES from '../messages';

export const useCoordinationSchema = () => {
    const { formatMessage } = useSafeIntl();
    return yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(formatMessage(MESSAGES.requiredField)),
        contact_emails: yup.array(yup.string().trim()),
        contact_phones: yup.array(yup.string().trim()),
        org_units: yup.array().of(
            yup.object().shape({
                id: yup.string(),
                name: yup.string(),
            }),
        ),
    });
};
