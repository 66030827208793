import React from 'react';
import {
    AnonymousRoutePath,
    RoutePath,
} from '../../../../../../hat/assets/js/apps/Iaso/constants/routes';

import { Home } from '../domains/Home/pages/Home';
import { ManagementCoordinations } from '../domains/Management/coordinations/index';
import { ManagementHealthStructures } from '../domains/Management/healthStructures/index';

import { baseUrls } from './urls';

export const homePath: RoutePath = {
    baseUrl: baseUrls.home,
    // routerUrl: baseUrls.home,
    routerUrl: `${baseUrls.home}/*`,
    permissions: [],
    element: <Home />,
    isRootUrl: true,
};

export const coordinationsPath: RoutePath = {
    baseUrl: baseUrls.coordinations,
    routerUrl: `${baseUrls.coordinations}/*`,
    permissions: ['iaso_trypelim_management_coordinations'],
    element: <ManagementCoordinations />,
};

export const healthStructuresPath: RoutePath = {
    baseUrl: baseUrls.healthStructures,
    routerUrl: `${baseUrls.healthStructures}/*`,
    permissions: ['iaso_trypelim_management_health_structures'],
    element: <ManagementHealthStructures />,
};

export const routes: (RoutePath | AnonymousRoutePath)[] = [
    homePath,
    coordinationsPath,
    healthStructuresPath,
];
