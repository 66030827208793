import polioConfig from './polio/js/config';
import registryConfig from './registry/js/config';
import testAppConfig from './test/js/config';
import trypelimConfig from './trypelim/assets/js/config';

export default {
    test: testAppConfig,
    polio: polioConfig,
    registry: registryConfig,
    trypelim: trypelimConfig,
};
