import { paginationPathParams } from '../../../../../../hat/assets/js/apps/Iaso/routing/common';
import {
    RouteConfig,
    extractParams,
    extractParamsConfig,
    extractUrls,
} from '../../../../../../hat/assets/js/apps/Iaso/constants/urls';

export const HOME_URL = 'trypelim/home';
export const COORDINATIONS_URL = 'trypelim/management/coordinations';
export const HEALTH_STRUCTURES_PATH = 'trypelim/management/health_structures';

export const trypelimRouteConfigs: Record<string, RouteConfig> = {
    home: {
        url: HOME_URL,
        params: [],
    },
    coordinations: {
        url: COORDINATIONS_URL,
        params: paginationPathParams,
    },
    healthStructures: {
        url: HEALTH_STRUCTURES_PATH,
        params: [...paginationPathParams, 'search'],
    },
};

export type TrypelimBaseUrls = {
    home: string;
    coordinations: string;
    healthStructures: string;
};

export const baseUrls = extractUrls(trypelimRouteConfigs) as TrypelimBaseUrls;
export const baseParams = extractParams(trypelimRouteConfigs);
export const paramsConfig = extractParamsConfig(trypelimRouteConfigs);
