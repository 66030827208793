/* eslint-disable camelcase */
import * as yup from 'yup';

import { useSafeIntl } from 'bluesquare-components';

import MESSAGES from '../messages';

export const useHealthStructureSchema = () => {
    const { formatMessage } = useSafeIntl();
    return yup.object().shape({
        name: yup
            .string()
            .trim()
            .required(formatMessage(MESSAGES.requiredField)),
        contact_emails: yup.array(yup.string().trim()),
        contact_phones: yup.array(yup.string().trim()),
    });
};
