import React, { FunctionComponent, useState } from 'react';

import { Box, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';

import {
    commonStyles,
    Table,
    useRedirectTo,
    useSafeIntl,
} from 'bluesquare-components';

import InputComponent from 'Iaso/components/forms/InputComponent';
import TopBar from 'Iaso/components/nav/TopBarComponent';
import { useFilterState } from 'Iaso/hooks/useFilterState';

import { baseUrls } from '../../../constants/urls';
import { useParamsObject } from '../../../../../../../../hat/assets/js/apps/Iaso/routing/hooks/useParamsObject';
import { useGetColumns } from './tableColumns';
import MESSAGES from './messages';
import { HealthStructureParams } from './types';

import {
    useGetHealthStructures,
    useSaveHealthStructure,
} from './hooks/requests';

const useStyles = makeStyles(theme => ({
    ...commonStyles(theme),
}));

type Props = {
    params: HealthStructureParams;
};

const baseUrl = baseUrls.healthStructures;
export const ManagementHealthStructures: FunctionComponent<Props> = () => {
    const params = useParamsObject(baseUrl);
    const redirectTo = useRedirectTo();
    const { formatMessage } = useSafeIntl();
    const classes = useStyles();

    const [textSearchError, setTextSearchError] = useState(false);

    const { filters, handleSearch, handleChange, filtersUpdated } =
        useFilterState({ baseUrl, params });

    const { data, isFetching } = useGetHealthStructures(params);
    const { mutateAsync: saveHealthStructure, isLoading: saving } =
        useSaveHealthStructure();

    const columns = useGetColumns(saveHealthStructure);

    const isLoading = isFetching || saving;

    return (
        <>
            <TopBar
                title={formatMessage(MESSAGES.health_structures)}
                displayBackButton={false}
            />
            <Box className={classes.containerFullHeightNoTabPadded}>
                <Grid container spacing={8} justifyContent="flex-end">
                    <Grid item xs={12} sm={6}>
                        <InputComponent
                            keyValue="search"
                            onChange={handleChange}
                            value={filters.search}
                            type="search"
                            label={MESSAGES.search}
                            blockForbiddenChars
                            onEnterPressed={handleSearch}
                            onErrorChange={hasError =>
                                setTextSearchError(hasError)
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            data-test="search-button"
                            disabled={!filtersUpdated || textSearchError}
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            onClick={() => handleSearch()}
                        >
                            <SearchIcon className={classes.buttonIcon} />
                            {formatMessage(MESSAGES.search)}
                        </Button>
                    </Grid>
                </Grid>
                <Table
                    data={data?.health_structures ?? []}
                    pages={data?.pages ?? 1}
                    defaultSorted={[{ id: 'name', desc: false }]}
                    columns={columns}
                    count={data?.count ?? 0}
                    baseUrl={baseUrl}
                    params={params}
                    extraProps={{
                        loading: isLoading,
                        defaultPageSize: 20,
                    }}
                    onTableParamsChange={p => redirectTo(baseUrl, p)}
                />
            </Box>
        </>
    );
};
