import React from 'react';
import moment from 'moment';
import { Column, useSafeIntl } from 'bluesquare-components';

import DeleteDialog from 'Iaso/components/dialogs/DeleteDialogComponent';
import MESSAGES from './messages';
import { EditCoordinationDialog } from './components/CoordinationDialog';
import { MutateFunction } from 'react-query';
import { Coordination } from './types';

export const useGetColumns = (
    saveCoordination: MutateFunction<Coordination, any>,
    deleteCoordination: MutateFunction<Coordination, any>,
    orgUnitTypeZoneId: number,
): Column[] => {
    const { formatMessage } = useSafeIntl();

    return [
        {
            Header: formatMessage({
                defaultMessage: 'Name',
                id: 'main.label.name',
            }),
            width: 300,
            accessor: 'name',
        },
        {
            Header: 'ZS (Org unit)',
            accessor: 'org_unit',
            Cell: settings => (
                <span>
                    {settings.row.original.org_units.map((orgUnit, index) =>
                        index === 0 ? orgUnit.name : `, ${orgUnit.name}`,
                    )}
                </span>
            ),
            width: 650,
            sortable: false,
        },
        {
            Header: formatMessage({
                defaultMessage: 'Creation date',
                id: 'main.label.creationDate',
            }),
            accessor: 'created_at',
            Cell: settings => (
                <span>
                    {moment(settings.row.original.created_at).format(
                        'YYYY-MM-DD',
                    )}
                </span>
            ),
        },
        {
            Header: formatMessage({
                defaultMessage: 'Contact details',
                id: 'main.label.contactDetails',
            }),
            sortable: false,
            Cell: settings => (
                <section>
                    {settings.row.original.contact_emails && (
                        <div>
                            {settings.row.original.contact_emails.join(', ')}
                        </div>
                    )}
                    {settings.row.original.contact_phones && (
                        <div>
                            {settings.row.original.contact_phones.join(', ')}
                        </div>
                    )}
                </section>
            ),
        },
        {
            Header: formatMessage(MESSAGES.actions),
            sortable: false,
            resizable: false,
            Cell: settings => (
                <section>
                    <EditCoordinationDialog
                        coordination={settings.row.original}
                        titleMessage={MESSAGES.edit}
                        saveCoordination={saveCoordination}
                        orgUnitTypeZoneId={orgUnitTypeZoneId}
                    />
                    <DeleteDialog
                        keyName="coordination"
                        titleMessage={MESSAGES.delete}
                        message={{
                            ...MESSAGES.deleteWarning,
                            values: {
                                name: settings.row.original.name,
                            },
                        }}
                        onConfirm={() =>
                            deleteCoordination(settings.row.original.id)
                        }
                    />
                </section>
            ),
        },
    ];
};
