import { defineMessages } from 'react-intl';

const MESSAGES = defineMessages({
    health_structures: {
        defaultMessage: 'Health structures',
        id: 'trypelim.navigation.management.healthStructures',
    },
    search: {
        defaultMessage: 'Search',
        id: 'iaso.search',
    },
    actions: {
        defaultMessage: 'Actions',
        id: 'main.label.actions',
    },
    view: {
        defaultMessage: 'View',
        id: 'main.label.view',
    },
    edit: {
        defaultMessage: 'Edit',
        id: 'main.label.edit',
    },
    cancel: {
        defaultMessage: 'Cancel',
        id: 'main.label.cancel',
    },
    save: {
        defaultMessage: 'Save',
        id: 'main.label.save',
    },
    requiredField: {
        id: 'iaso.forms.error.fieldRequired',
        defaultMessage: 'This field is required',
    },
    name: {
        defaultMessage: 'Name',
        id: 'main.label.name',
    },
    contactEmails: {
        defaultMessage: 'Contact emails',
        id: 'main.label.contact_emails',
    },
    contactPhones: {
        defaultMessage: 'Contact phones',
        id: 'main.label.contact_phones',
    },
});

export default MESSAGES;
